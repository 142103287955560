import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { ChecklistQuestions } from "../../../core/constants/translation-namespace";
import { useMenu } from "../../../core/store/menu-context";
import {
    EndAlignedDiv,
    PageHeading,
    SectionVerticalSpace,
} from "../../../core/theme/global-styles";
import { AccordionTitles, DrawerTitles, NavbarTitles } from "../../../core/utilities/enums";
import CreateQuestionCategoryDto from "../../../domain/dtos/question-categories/create-question-category-dto";
import { useCreateQuestionCategory } from "../../../domain/viewmodels/question-categories/create-question-category-viewmodel";
import { CancelButton, SaveButton } from "../../atoms/SbButton";
import SbFormikTextFieldGroup from "../../molecules/input/SbFormikTextFieldGroup";
import { TextTitledPanel } from "../../molecules/SbPanel";
import { useErrorResponseToDisplayHandler } from "../../../core/hooks/errorResponseToDisplayHandler";

const CreateQuestionCategoryContainer: React.FC = () => {
    const menu = useMenu();
    const navigate = useNavigate();
    const errorResponseToDisplayHandler = useErrorResponseToDisplayHandler();
    const { t } = useTranslation("translation", { keyPrefix: ChecklistQuestions });

    useEffect(() => {
        menu.changeActiveNavbarItem(NavbarTitles.Admin);
        menu.changeActiveDrawerItem(DrawerTitles.Library, AccordionTitles.QuestionCategories);
    }, []);

    const createQuestionCategory = useCreateQuestionCategory();

    const handleSubmit = (dto: CreateQuestionCategoryDto): void => {
        createQuestionCategory.mutate(dto, {
            onSuccess: async () => navigate(-1),
            onError: errorResponseToDisplayHandler,
        });
    };

    const createQuestionCategorySchema = (): object => {
        return object({
            name: string().max(100, "Must be 100 characters or less").required("Name is required"),
        });
    };

    return (
        <>
            <PageHeading>{t("QuestionCategoryCreateTitle")}</PageHeading>
            <SectionVerticalSpace />
            <TextTitledPanel title={t("CreateQuestionCategory")}>
                <Formik
                    initialValues={new CreateQuestionCategoryDto("")}
                    onSubmit={handleSubmit}
                    validationSchema={createQuestionCategorySchema}
                >
                    {({ handleChange, errors, touched }) => (
                        <Form>
                            <SbFormikTextFieldGroup
                                name="name"
                                label={t("Name")}
                                type="text"
                                required
                                onFormikChange={handleChange}
                                error={errors.name}
                                touched={touched.name}
                            />

                            <EndAlignedDiv>
                                <SaveButton type="submit" />
                                <CancelButton onClick={() => navigate(-1)} />
                            </EndAlignedDiv>
                        </Form>
                    )}
                </Formik>
            </TextTitledPanel>
        </>
    );
};

export default CreateQuestionCategoryContainer;
